.Reviews-main {
    padding: 30px;
}

.Reviews-header {
    font-weight: bolder;
}

.Reviews-item {
    margin: 10px;
}