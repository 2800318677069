.header-main {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.header-name {
    padding: 20px;
    font-weight: bold;
}
.header-address {
    padding: 20px;
}
.header-phone {
    padding: 20px;
}